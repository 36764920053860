'use client';

import { Typography } from 'ui';

interface Props {
  title: string;
  align?: 'left' | 'center';
}

export const Title = ({ title, align = 'left' }: Props) => {
  return (
    <Typography textAlign={align} fontWeight="bold" variant="h6">
      {title}
    </Typography>
  );
};
