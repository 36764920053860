import { useTranslation } from 'react-i18next';
import { Box } from 'ui';

import { useCreateMeteringPoint } from '~/meteringPoint';
import { OptionCard, StepTitle } from '~/ui';

export const AccountOptionsStep = () => {
  const { t } = useTranslation('createMeteringPointWizard');
  const { goToNextStep } = useCreateMeteringPoint();

  return (
    <Box>
      <Box mb={2}>
        <StepTitle>{t('steps.accountOptions.title')}</StepTitle>
      </Box>

      <Box mb={2}>
        <OptionCard title={t('steps.accountOptions.currentAccount')} onClick={goToNextStep} />
      </Box>

      <OptionCard title={t('steps.accountOptions.newAccount')} onClick={() => {}} />
    </Box>
  );
};
