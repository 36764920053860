'use client';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Loader, Paper, useTheme } from 'ui';

import { usePaymentNotification } from '~/payment/hooks';

import { PaymentNotAvailable } from './PaymentNotAvailable';
import { PaymentOptionCard } from './PaymentOptionCard';
import { Title } from './Title';

interface Props {
  invoiceId: string;
  invoiceNumber: string;
  totalInvoiceCost: number;
}

export const PaymentMethods = ({ invoiceId, invoiceNumber, totalInvoiceCost }: Props) => {
  const { customShadows } = useTheme();
  const { t } = useTranslation('invoices');
  const { isLoading, isPaymentAvailable } = usePaymentNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onPaymentInit = async () => {
    setIsSubmitting(true);
  };

  if (isLoading()) {
    return (
      <Box p={8} display="flex" alignItems="center" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  if (!isPaymentAvailable() && !isSubmitting) {
    return <PaymentNotAvailable />;
  }

  return (
    <Box mb={4}>
      <Paper
        sx={{
          boxShadow: customShadows.main,
          p: {
            xs: 2,
          },
        }}
      >
        <Title title={t('paymentAmount', { value: totalInvoiceCost })} />

        <Box mb={2}>
          <Title title={t('pay', { value: totalInvoiceCost })} />
        </Box>

        <Box mb={4}>
          <PaymentOptionCard
            invoiceId={invoiceId}
            invoiceNumber={invoiceNumber}
            totalInvoiceCost={totalInvoiceCost}
            paymentOption="paylogin"
            onPaymentInit={onPaymentInit}
          />
        </Box>

        <Box mb={2}>
          <PaymentOptionCard
            invoiceId={invoiceId}
            invoiceNumber={invoiceNumber}
            totalInvoiceCost={totalInvoiceCost}
            paymentOption="credit_paydirect"
            onPaymentInit={onPaymentInit}
          />
        </Box>
      </Paper>
    </Box>
  );
};
