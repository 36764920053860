'use client';

import logger from 'logger';
import { useTranslation } from 'react-i18next';
import { Box, DetailsPageTitle } from 'ui';

import { useInvoiceBalance } from '~/invoice/hooks/useInvoiceBalance/useInvoiceBalance';
import { PaymentNotificationProvider } from '~/payment/hooks';
import { GoBackButton } from '~/ui';

import { useInvoice } from '../../hooks/useInvoice/useInvoice';
import { DownloadButtons } from '../shared';
import { PaymentMethods } from './PaymentMethods';

interface Props {
  invoiceId: string;
}

export const InvoicePayment = ({ invoiceId }: Props) => {
  const { t } = useTranslation('invoices');

  const { data: invoice } = useInvoice({ invoiceId });

  const statusText = useInvoiceBalance({ balance: invoice?.balance });
  const status = t(`${invoice?.status.toLowerCase()}`);

  if (!invoice) {
    logger.debug('Invoice not found');
    return null;
  }

  return (
    <PaymentNotificationProvider invoice={invoice}>
      <Box mb={4}>
        <DetailsPageTitle
          title={t('invoice')}
          subtitle={invoice?.invoiceNumber || ''}
          secondaryTitle={t('status')}
          secondarySubtitle={statusText || status}
          leftButtons={<GoBackButton />}
          rightButtons={<DownloadButtons invoiceId={invoiceId} />}
        />
      </Box>

      {invoice && (
        <PaymentMethods
          invoiceId={invoiceId}
          invoiceNumber={invoice.invoiceNumber}
          totalInvoiceCost={invoice.totalInvoiceCost}
        />
      )}
    </PaymentNotificationProvider>
  );
};
