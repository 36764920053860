import { useTranslation } from 'react-i18next';
import { Box, Loader, Typography } from 'ui';

export const LoadingMessage = () => {
  const { t } = useTranslation('createMeteringPointWizard');

  return (
    <Box display="flex" flex={1} alignItems="center" justifyContent="flex-start" flexDirection="column" height="100%">
      <Box mb={2}>
        <Typography variant="h5">{t('steps.completion.loadingMessageTitle')}</Typography>
      </Box>

      <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column">
        <Box mb={1}>
          <Typography data-testid="contract-generation-loading-message" variant="body1" textAlign="center">
            {t('steps.completion.loadingMessage1')}
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="body1" textAlign="center">
            {t('steps.completion.loadingMessage2')}
          </Typography>
        </Box>

        <Loader />
      </Box>
    </Box>
  );
};
