'use client';

import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from 'ui';

import { Title } from './Title';

export const PaymentNotAvailable = () => {
  const { t } = useTranslation('invoices');

  return (
    <Box mb={4}>
      <Paper
        sx={{
          p: {
            xs: 2,
          },
        }}
      >
        <Box mt={2} mb={2}>
          <Title title={t('paymentNotAvailable.title')} align="center" />
        </Box>

        <Box mb={4}>
          <Typography variant="body1" textAlign="center">
            {t('paymentNotAvailable.description')}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
