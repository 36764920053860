import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'ui';

import { NextLink } from '~/ui';

export const RegisteredCustomerMessage = () => {
  const { t } = useTranslation('createCustomerWizard');

  return (
    <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column" height="100%">
      <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column">
        <Box mb={2}>
          <Typography variant="h5" data-testid="customer-already-registered-title">
            {t('steps.customerAlreadyRegistered.title')}
          </Typography>
        </Box>

        <Box mb={1}>
          <Typography variant="body1" textAlign="center">
            {t('steps.customerAlreadyRegistered.description1')}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="body1" textAlign="center">
            {t('steps.customerAlreadyRegistered.description2')}
          </Typography>
        </Box>

        <NextLink href="/invoices">
          <Typography variant="body1" color="primary">
            {t('steps.customerAlreadyRegistered.button')}
          </Typography>
        </NextLink>
      </Box>
    </Box>
  );
};
