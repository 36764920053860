'use client';

import logger from 'logger';

import { useAuthFetch } from './useAuthFetch';

export const useDriveFile = () => {
  const { request } = useAuthFetch();

  const getFile = async ({ fileUrl }: { fileUrl: string }) => {
    const match = fileUrl.match(/id=([^&]*)/);
    const fileId = match && match[1];

    const response = await request(`/api/drive-files?fileId=${fileId}`, {
      method: 'GET',
    });

    const fileContent = await response.blob();

    if (!response.ok) {
      logger.error('Failed to fetch file');
    }

    return { fileContent };
  };

  return { getFile };
};
