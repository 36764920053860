import { doc, onSnapshot } from 'firebase/firestore';
import { db } from 'firebase-client';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { InvoiceDetails } from '~/data';
import { useInvoice } from '~/invoice';
import { Payment } from '~/services/firestore/payment';
import { PaymentStatus } from '~/services/firestore/payment/types';

type PaymentNotification = {
  paymentStatus: PaymentStatus | null;
  isLoading: boolean;
};

interface PaymentNotificationContextOptions {
  getNotificationStatus: () => PaymentStatus | null;
  isPaymentAvailable: () => boolean;
  isLoading: () => boolean;
}

const PaymentNotificationContext = createContext<PaymentNotificationContextOptions>({
  getNotificationStatus: () => null,
  isPaymentAvailable: () => false,
  isLoading: () => false,
});

interface PaymentNotificationProviderProps {
  children: React.ReactNode;
  invoice: InvoiceDetails;
}

export const PaymentNotificationProvider = ({ children, invoice }: PaymentNotificationProviderProps) => {
  const [notification, setNotification] = useState<PaymentNotification>({
    paymentStatus: null,
    isLoading: true,
  });
  const { refetch } = useInvoice({ invoiceId: invoice.invoiceId });

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'payments', invoice.invoiceNumber), (snapshot) => {
      const data = snapshot.data() as Payment | undefined;

      if (!data) {
        setNotification({
          paymentStatus: null,
          isLoading: false,
        });

        return;
      }

      setNotification({
        paymentStatus: data.status,
        isLoading: false,
      });

      if (data.status === PaymentStatus.PAID && invoice.status === 'Unpaid') {
        refetch();
      }
    });

    return () => unsubscribe();
  }, [invoice.invoiceNumber, invoice.status, refetch]);

  const getNotificationStatus = useCallback(() => notification.paymentStatus, [notification.paymentStatus]);

  const isPaymentAvailable = useCallback(
    () => !notification.paymentStatus && !notification.isLoading,
    [notification.paymentStatus, notification.isLoading]
  );

  const isLoading = useCallback(() => notification.isLoading, [notification.isLoading]);

  const value = useMemo(
    () => ({
      getNotificationStatus,
      isPaymentAvailable,
      isLoading,
    }),
    [getNotificationStatus, isPaymentAvailable, isLoading]
  );

  return <PaymentNotificationContext.Provider value={value}>{children}</PaymentNotificationContext.Provider>;
};

export const usePaymentNotification = (): PaymentNotificationContextOptions => {
  return useContext(PaymentNotificationContext);
};
