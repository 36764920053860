import { useAuthFetch } from './useAuthFetch';

interface Init {
  invoiceNumber: string;
  encoded: string;
}

type InitReturnType = {
  data: {
    invoiceNumber: string;
  };
};

type UseEPayInitReturnType = {
  init: (payload: Init) => Promise<InitReturnType>;
};

const useEPayInitRequest = (): UseEPayInitReturnType => {
  const { request } = useAuthFetch();

  const init = async ({ encoded, invoiceNumber }: Init): Promise<InitReturnType> => {
    const reponse = await request('/api/payments/epay-init', {
      body: JSON.stringify({
        encoded,
        invoiceNumber,
      }),
      method: 'POST',
    });

    if (!reponse.ok) {
      throw new Error('Failed to init payment');
    }

    return reponse.json() as Promise<InitReturnType>;
  };

  return { init };
};

export { useEPayInitRequest };
