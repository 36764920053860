'use client';

import { useRouter } from 'next/navigation';
import { Button, useResolution } from 'ui';
import { ArrowBack } from 'ui/icons';

interface Props {
  onClick?: () => void;
}

export const GoBackButton = ({ onClick }: Props) => {
  const { back } = useRouter();
  const { isTablet } = useResolution();

  const goBack = onClick || back;

  return (
    <Button variant="square" data-testid="go-back" onClick={goBack} size={isTablet ? 'md' : 'sm'}>
      <ArrowBack />
    </Button>
  );
};
