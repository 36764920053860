import { Configuration, GetPlanPricesV1200Response, PriceApi } from '@toki-power/public-api';

type PlanReturnType = {
  getAll: () => Promise<GetPlanPricesV1200Response>;
};

export const planPriceApi = (config: () => Promise<Configuration>): PlanReturnType => {
  const api = async () => new PriceApi(await config());

  const getAll = async () => {
    const response = await (await api()).getPlanPricesV1();

    if (response.items === undefined) {
      return { items: [] };
    }

    return response;
  };

  return {
    getAll,
  };
};
