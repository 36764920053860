import { useSuspenseQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys, InvoiceDetails, transformInvoice } from '~/data';
import { ResponseError, useInvoiceApi } from '~/data/api';

const cacheKeys = createDataCacheKeys('invoices');

type UseInvoicesResult = BaseHTTPResponse<InvoiceDetails | undefined> & {
  refetch: () => void;
};

type InvoiceOptions = {
  invoiceId: string;
};

export const useInvoice = ({ invoiceId }: InvoiceOptions): UseInvoicesResult => {
  const api = useInvoiceApi();

  const { data, error, isLoading, isError, isSuccess, refetch } = useSuspenseQuery<InvoiceDetails, ResponseError>({
    queryFn: () => api.get(invoiceId),
    queryKey: cacheKeys.detail(invoiceId),
  });

  return {
    data: transformInvoice(data),
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
