import { Configuration } from '@toki-power/public-api';

import ErrorHandlerMiddleware from './ErrorHandlerMiddleware';

export const getApiKeyConfig = (): Promise<Configuration> => {
  const apiKey = process.env.BACKOFFICE_API_KEY;

  if (!apiKey) {
    throw new Error('API key is not set');
  }

  return Promise.resolve(
    new Configuration({
      basePath: process.env.NEXT_PUBLIC_BACKOFFICE_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      middleware: [new ErrorHandlerMiddleware()],
    })
  );
};
