import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys } from '~/data';
import { useEPayInitRequest } from '~/data/appApi';

type InitReturnType = Awaited<ReturnType<ReturnType<typeof useEPayInitRequest>['init']>>;

const cacheKeys = createDataCacheKeys('epayInit');

interface Init {
  encoded: string;
}

type UseEPayInitReturnType = Omit<BaseHTTPResponse<InitReturnType>, 'error' | 'data'> & {
  error: Error | null;
  init: UseMutateAsyncFunction<InitReturnType, Error, Init>;
};

interface UseEPayInit {
  invoiceNumber: string;
}

const useEPayInit = ({ invoiceNumber }: UseEPayInit): UseEPayInitReturnType => {
  const { init: initRequest } = useEPayInitRequest();

  const { mutateAsync, isError, isSuccess, isPending, error } = useMutation({
    mutationFn: async ({ encoded }: Init) => {
      return initRequest({ encoded, invoiceNumber });
    },
    mutationKey: cacheKeys.detail(`epay-init-${invoiceNumber}`),
  });

  return {
    error,
    init: mutateAsync,
    isError,
    isLoading: isPending,
    isSuccess,
  };
};

export { useEPayInit };
