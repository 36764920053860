import { useTranslation } from 'react-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const Representatives = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData, setCurrentRepresentativeIndex, removeRepresentative } = useCreateCustomer();

  const customerData = getCustomerData();
  const { representatives } = customerData;

  const setRepresentativeStep = (index: number) => {
    setCurrentRepresentativeIndex(index);
    onClick(`businessRepresentative${index + 1}`);
  };

  if (representatives.length === 0) {
    return (
      <DataSection
        label={t('steps.confirmation.businessRepresentative')}
        Value={<Value>{t('steps.confirmation.noData')}</Value>}
        onClick={() => setRepresentativeStep(0)}
        error={t('steps.confirmation.error')}
        data-testid="business-representative-1"
      />
    );
  }

  return representatives.map((representative, index) => (
    <DataSection
      key={`${representative.firstName}-${representative.middleName}-${representative.lastName}`}
      label={t('steps.confirmation.businessRepresentative')}
      Value={
        <>
          <Value>
            {representative.firstName} {representative.middleName} {representative.lastName}
          </Value>
          {representative.firstName || representative.middleName || representative.lastName ? null : (
            <Value>{t('steps.confirmation.missingData')}</Value>
          )}
        </>
      }
      deleteIcon={index === 1}
      onDelete={() => removeRepresentative(index)}
      onClick={() => setRepresentativeStep(index)}
      error={
        !representative.firstName || !representative.middleName || !representative.lastName
          ? t('steps.confirmation.error')
          : ''
      }
      data-testid={`business-representative-${index + 1}`}
    />
  ));
};
