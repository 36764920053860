import { useQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys } from '~/data';
import { useEPayPayloadRequest } from '~/data/appApi';

import { PaymentPayload } from '../utils/epay/types';

const cacheKeys = createDataCacheKeys('epayPayload');

type UseEPayPayloadReturnType = Omit<BaseHTTPResponse<PaymentPayload | null>, 'error'> & {
  error: Error | null;
};

interface UseEPayPayload {
  invoiceNumber: string;
  amount: number;
}

const useEPayPayload = ({ invoiceNumber, amount }: UseEPayPayload): UseEPayPayloadReturnType => {
  const { getPayload } = useEPayPayloadRequest<PaymentPayload>();

  const {
    data: payload,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useQuery({
    enabled: !!invoiceNumber && !!amount,
    queryFn: async () => {
      const { error: payloadError, data } = await getPayload({ amount, invoiceNumber });

      if (payloadError) {
        throw payloadError;
      }

      return data;
    },
    queryKey: [cacheKeys.detail(`${invoiceNumber}-${amount}`)],
  });

  return {
    data: payload || null,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};

export { useEPayPayload };
