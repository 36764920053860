'use client';

import { useParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'ui';

interface Props {
  Component: React.ElementType;
}

const InvoiceDetailsContainer = ({ Component }: Props) => {
  const { t } = useTranslation();

  const params = useParams();
  const id = params && params.id;

  if (typeof id !== 'string') {
    return (
      <Box>
        <Typography variant="h1">{t('invalidInvoiceNumber')}</Typography>
      </Box>
    );
  }

  return <Component invoiceId={id} />;
};

export default InvoiceDetailsContainer;
