import { useMemo } from 'react';
import { BillingPlans } from 'types';

import { usePlanPrices } from '../usePlanPrices/usePlanPrices';

export type PlanPrice = {
  price: string;
  billingPlan: keyof typeof BillingPlans;
  isGreen: boolean;
  promoPrice?: string;
  hasAdditionalInfo: boolean;
  planType: string;
};

const AVAILABLE_PLANS: Array<keyof typeof BillingPlans> = ['plan1', 'plan8', 'plan18', 'plan13', 'plan3', 'plan17'];

type PlanDetails = {
  [planType: string]: PlanPrice;
};

const planDetails: PlanDetails = {
  plan1: {
    billingPlan: 'plan1',
    hasAdditionalInfo: false,
    isGreen: false,
    planType: 'free',
    price: '',
  },
  plan13: {
    billingPlan: 'plan13',
    hasAdditionalInfo: false,
    isGreen: true,
    planType: 'greenFree',
    price: '',
  },
  plan17: {
    billingPlan: 'plan17',
    hasAdditionalInfo: true,
    isGreen: true,
    planType: 'greenExchange',
    price: '',
    promoPrice: '',
  },
  plan18: {
    billingPlan: 'plan18',
    hasAdditionalInfo: true,
    isGreen: false,
    planType: 'exchange',
    price: '',
    promoPrice: '',
  },
  plan3: {
    billingPlan: 'plan3',
    hasAdditionalInfo: false,
    isGreen: true,
    planType: 'greenStandard',
    price: '',
  },
  plan8: {
    billingPlan: 'plan8',
    hasAdditionalInfo: false,
    isGreen: false,
    planType: 'standard',
    price: '',
  },
};

const formatPrice = (price: number): string => {
  if (price === 0) {
    return '0';
  }

  return `${(price * 0.001).toFixed(3)}`;
};

export const useAvailablePrices = (): PlanPrice[] => {
  const { data } = usePlanPrices();

  const plans: PlanPrice[] = useMemo(() => {
    return AVAILABLE_PLANS.reduce<PlanPrice[]>((acc, planId) => {
      const planPrice = data.items
        .filter((item) => item.planId === BillingPlans[planId])
        .sort((a, b) => a.price - b.price);

      if (planPrice.length === 0) {
        return acc;
      }

      const plan = planDetails[planId];

      const currentPlan: PlanPrice = {
        billingPlan: planId,
        hasAdditionalInfo: plan.hasAdditionalInfo,
        isGreen: plan.isGreen,
        planType: plan.planType,
        price: formatPrice(planPrice[0].price),
      };

      if ('promoPrice' in plan && planPrice[1].price) {
        currentPlan.promoPrice = formatPrice(planPrice[1].price);
      }

      return [...acc, currentPlan];
    }, []);
  }, [data.items]);

  return plans;
};
