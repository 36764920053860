'use client';

import { auth, onAuthStateChanged } from 'firebase-client';
import { useEffect } from 'react';

import { Attributes } from '../types';
import { setAttributes } from '../utils/setAttributes';

export const useSetAttributesOnAuthChange = (attributes?: Attributes) => {
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setAttributes({ email: user ? user.email : null, ...attributes });
    });
  }, [attributes]);
};
