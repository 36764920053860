import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from 'ui';

interface ErrorMessageProps {
  message?: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  const { t } = useTranslation('createMeteringPointWizard');

  return (
    <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column" height="100%">
      <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column">
        <Box mb={2}>
          <Typography data-testid="contract-generation-error" variant="h5">
            {t('steps.completion.errorOnContractGenerationTitle')}
          </Typography>
        </Box>

        {message && (
          <Typography variant="body1" textAlign="center">
            {message}
          </Typography>
        )}

        <Typography variant="body1" textAlign="center">
          {t('steps.completion.errorOnContractGeneration')}
        </Typography>

        <Link href={`mailto:${t('steps.completion.supportEmail')}`}>{t('steps.completion.supportEmail')}</Link>
      </Box>
    </Box>
  );
};
