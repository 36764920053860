'use client';

import { useTranslation } from 'react-i18next';
import { Button } from 'ui';

interface ContinueButtonProps {
  'data-testid'?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const ContinueButton = ({ 'data-testid': dataTestId, loading, disabled }: ContinueButtonProps) => {
  const { t } = useTranslation('common');
  return (
    <Button
      type="submit"
      size="lg"
      fullWidth
      data-testid={dataTestId}
      loading={loading}
      disabled={disabled}
      color="gradient"
    >
      {t('continue')}
    </Button>
  );
};
