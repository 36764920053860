import { useTranslation } from 'react-i18next';
import { CardListContainer } from 'ui';

import { useAvailablePrices } from '../../hooks';
import { PlanCard } from '../detail';

interface Props {
  baseUrl: string;
}

export const PlanCardList = ({ baseUrl }: Props) => {
  const { t } = useTranslation('plans');
  const plans = useAvailablePrices();

  return (
    <CardListContainer
      cardWidth="md"
      elements={plans.map(({ planType, isGreen, price, billingPlan, promoPrice, hasAdditionalInfo }) => (
        <PlanCard
          title={t(`plans.${planType}.title`)}
          subtitle={isGreen ? t(`plans.${planType}.subtitle`) : undefined}
          details={t(`plans.${planType}.details`, { promoPrice, returnObjects: true }) as string[]}
          price={
            planType.toLowerCase().includes('exchange')
              ? t('exchangePrice', { exchangePrice: price })
              : t('price', { price })
          }
          isGreen={isGreen}
          billingPlan={billingPlan}
          key={billingPlan}
          baseUrl={baseUrl}
          promoPrice={promoPrice}
          additionalInfo={hasAdditionalInfo ? t(`plans.${planType}.additionalInfo`) : undefined}
        />
      ))}
    />
  );
};
