import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'ui';

import { useEPayInit, useEPayPayload } from '../hooks';

export type PaymentOption = 'paylogin' | 'credit_paydirect';

interface Props {
  invoiceId: string;
  invoiceNumber: string;
  amount: number;
  paymentOption: PaymentOption;
  onPaymentInit: () => Promise<void>;
}

const BUTTON_LABELS = {
  credit_paydirect: 'ePayCard.payWithCard',
  paylogin: 'ePayProfile.payWithEPay',
};

const EPayButton = memo(({ invoiceNumber, invoiceId, amount, paymentOption, onPaymentInit }: Props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    isLoading,
    data: payload,
    error,
  } = useEPayPayload({
    amount,
    invoiceNumber,
  });
  const redirectUrl = `${window.location.origin}/invoices/${invoiceId}/?ref=epay`;

  const { isLoading: isInitStarted, init } = useEPayInit({ invoiceNumber });

  const isDisabled = Boolean(isLoading || !payload || error);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    event.preventDefault();

    if (!payload) {
      return;
    }

    await onPaymentInit();
    await init({ encoded: payload.encoded });

    formRef.current?.submit();
  };

  return (
    <Box display="flex" flex={1}>
      <form action={process.env.NEXT_PUBLIC_EPAY_SUBMIT_URL} method="POST" onSubmit={onSubmit} ref={formRef}>
        {payload?.checksum && payload?.encoded && (
          <>
            <input type="hidden" name="PAGE" value={paymentOption} />
            <input type="hidden" name="ENCODED" value={payload.encoded} />
            <input type="hidden" name="CHECKSUM" value={payload.checksum} />
            <input type="hidden" name="URL_OK" value={redirectUrl} />
            <input type="hidden" name="URL_CANCEL" value={redirectUrl} />
          </>
        )}

        <Box
          sx={{ bgcolor: 'primary.main', borderRadius: 4 }}
          display="flex"
          flex={1}
          flexDirection="row"
          alignItems="center"
        >
          <Button
            type="submit"
            color="gradient"
            loading={isInitStarted || isSubmitting}
            disabled={isDisabled || paymentOption === null}
          >
            {t(`invoices:${BUTTON_LABELS[paymentOption]}`)}
          </Button>
        </Box>
      </form>
    </Box>
  );
});

export { EPayButton };
