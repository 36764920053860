import { useAuthUser } from '~/auth';

const useAuthFetch = () => {
  const { getToken } = useAuthUser();

  const request = async (url: string, options: RequestInit) => {
    const token = await getToken();

    if (!token) {
      return Promise.reject(new Error('No token'));
    }

    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
  };

  return { request };
};

export { useAuthFetch };
