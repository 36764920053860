import { Timestamp } from 'firebase/firestore';

export enum PaymentStatus {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  ERROR = 'ERROR',
}

export type Payment = {
  invoiceNumber: string;
  userId: string;
  status: PaymentStatus;
  paymentNotificationPayload: string | null;
  paymentRequestPayload: string | null;
  isSonitaNotified: boolean;
  sonitaResponse: string | null;
  isInvoiceStatusUpdated: boolean;
  createdAt: Timestamp;
};
