'use client';

import { useTranslation } from 'react-i18next';
import { Box, Card, Typography, useTheme } from 'ui';

import { EPayButton, PaymentOption } from '~/payment';

interface Props {
  invoiceId: string;
  invoiceNumber: string;
  totalInvoiceCost: number;
  paymentOption: PaymentOption;
  onPaymentInit: () => Promise<void>;
}

export const PaymentOptionCard = ({
  invoiceNumber,
  invoiceId,
  totalInvoiceCost,
  paymentOption,
  onPaymentInit,
}: Props) => {
  const { t } = useTranslation('invoices');
  const { customShadows } = useTheme();

  return (
    <Card
      sx={{
        borderRadius: 4,
        boxShadow: customShadows.main,
        height: '100%',
        p: 2,
      }}
    >
      <Box>
        <Typography variant="h6" color="info.main" fontWeight="bold">
          {t('ePayProfile.title')}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography variant="body2">{t('ePayProfile.description')}</Typography>
      </Box>

      <EPayButton
        invoiceId={invoiceId}
        invoiceNumber={invoiceNumber}
        amount={totalInvoiceCost}
        paymentOption={paymentOption}
        onPaymentInit={onPaymentInit}
      />
    </Card>
  );
};
