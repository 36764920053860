import { useTranslation } from 'react-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const Contact = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();

  const customerData = getCustomerData();
  const { contact, firstName, lastName } = customerData;

  const contactData = [
    firstName || lastName ? `${firstName} ${lastName}` : null,
    contact.email,
    contact.phone.value ? `${contact.phone.countryCode}${contact.phone.value}` : null,
  ].filter(Boolean);

  return (
    <DataSection
      label={t('steps.confirmation.contact')}
      Value={<Value>{contactData.length > 0 ? contactData.join(', ') : t('steps.confirmation.noData')}</Value>}
      onClick={() => onClick('contactPerson')}
      error={contactData.length === 0 ? t('steps.confirmation.error') : ''}
      data-testid="contact"
    />
  );
};
