import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import Card from '../Card';
import { CurrencyFormat } from '../CurrencyFormat/CurrencyFormat';
import { Divider } from '../Divider';

interface ProtocolCardProps {
  subtitle: string;
  title: string;
  amount: number;
  generateInvoiceButton?: ReactNode;
  details: ReactNode[];
  customerName?: string;
  downloadButtons: ReactNode[];
  ['data-testid']?: string;
}

const ProtocolCard = ({
  amount,
  subtitle,
  title,
  generateInvoiceButton,
  details,
  customerName,
  'data-testid': dataTestId,
  downloadButtons,
}: ProtocolCardProps) => {
  return (
    <Card
      data-testid={dataTestId}
      headerActions={generateInvoiceButton}
      title={
        <Typography variant="body2" color="info.main">
          {subtitle}
        </Typography>
      }
      subtitle={
        <Box display="inline-flex">
          <Typography variant="h6" color="info.main" fontWeight="bold" mt={0.5} textTransform="capitalize">
            {title}
          </Typography>
        </Box>
      }
      content={
        <Stack color="textColor.light">
          {details.map((detail, i) => (
            <Typography key={i} mb={0.5} variant="body2">
              {detail}
            </Typography>
          ))}
        </Stack>
      }
      actions={
        <Box width="100%">
          <CurrencyFormat value={amount} variant="h5" fontWeight="bold" color="primary.main" />
          <Divider sx={{ backgroundColor: 'textColor.main', my: 2 }} />
          <Box flexWrap="wrap" display="flex" flexDirection="row" justifyContent="space-between">
            {downloadButtons.map((button, i) => (
              <Box key={i}>{button}</Box>
            ))}
          </Box>
        </Box>
      }
      cardGradient={customerName}
    />
  );
};

export default ProtocolCard;
