/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import {
  Checkbox,
  FormProvider,
  PhoneInput,
  SubmitHandler,
  TextInput,
  useForm,
  validatePhoneNumber,
  yupResolver,
} from 'forms';
import logger from 'logger';
import { useTranslation } from 'react-i18next';
import { Phone } from 'types';
import { Box, Button, Form } from 'ui';
import { bool, mixed, object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { StepTitle } from '~/ui';

interface FormValues {
  firstName?: string;
  lastName?: string;
  phone: Phone;
  email?: string;
  useCustomerContact?: boolean;
}

export const BillingContactPersonStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const {
    goToNextStep,
    getCustomerBillingContact,
    getCustomerContact,
    setCustomerBillingContact,
    navigateWithRedirect,
    getResidentialCustomerContact,
    isCompany,
  } = useCreateCustomer();

  const customerContact = getCustomerContact();
  const { phone, email, firstName, lastName } = getCustomerBillingContact();

  const resolver = yupResolver(
    object({
      email: string().when('useCustomerContact', {
        is: false,
        then: () =>
          string()
            .required(t('validation:required'))
            .email(t('steps.billingContact.fields.email.invalid'))
            .test(
              'billingEmailValidation',
              t('steps.billingContact.fields.email.invalidBillingEmail'),
              (value) => value !== customerContact.email
            ),
      }),
      firstName: string().when('useCustomerContact', {
        is: false,
        then: () => string().required(t('steps.billingContact.fields.firstName.required')),
      }),
      lastName: string().when('useCustomerContact', {
        is: false,
        then: () => string().required(t('steps.billingContact.fields.lastName.required')),
      }),
      phone: mixed<Phone>()
        .defined()
        .when('useCustomerContact', {
          is: false,
          then: () =>
            mixed<Phone>()
              .defined()
              .test('phoneValidation', t('validation:invalidPhone'), ({ country, countryCode, value }) =>
                validatePhoneNumber(country, countryCode, value)
              ),
        }),
      useCustomerContact: bool(),
    })
  );

  const { handleSubmit, watch, setValue, resetField, clearErrors, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      email,
      firstName,
      lastName,
      phone,
      useCustomerContact: false,
    },
    mode: 'onChange',
    resolver,
  });

  const useCustomerContact = watch('useCustomerContact');

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.billingData);

    if (values.useCustomerContact) {
      goToNextStep();

      return;
    }

    if (values.email && values.firstName && values.lastName && values.phone) {
      setCustomerBillingContact({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
      });

      navigateWithRedirect(goToNextStep);

      return;
    }

    logger.error('Error while submitting billing contact form', { values });
  };

  const handleUseCustomerContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const residentialCustomerContact = getResidentialCustomerContact();

    const customerBillingContact = isCompany() ? customerContact : residentialCustomerContact;

    const isChecked = event.target.checked;

    if (isChecked) {
      Object.entries(customerBillingContact).forEach(([key, value]) =>
        setValue(key as keyof typeof customerBillingContact, value)
      );
    } else {
      Object.keys(customerBillingContact).forEach((key) => resetField(key as keyof typeof customerBillingContact));
    }

    clearErrors();
  };

  return (
    <FormProvider {...{ clearErrors, formState, handleSubmit, resetField, setValue, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.billingContact.title')}</StepTitle>
          </Box>

          <Checkbox
            name="useCustomerContact"
            label={t('steps.billingContact.fields.useCustomerContact.label')}
            data-testid="use-customer-contact"
            onChange={handleUseCustomerContactChange}
          />

          <Box display="flex" flexDirection="column" gap={1}>
            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.billingContact.fields.firstName.label`)}
              placeholder={t(`steps.billingContact.fields.firstName.placeholder`)}
              name="firstName"
              shrink
              data-testid="first-name"
              disabled={useCustomerContact}
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.billingContact.fields.lastName.label`)}
              placeholder={t(`steps.billingContact.fields.lastName.placeholder`)}
              name="lastName"
              shrink
              data-testid="last-name"
              disabled={useCustomerContact}
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.billingContact.fields.email.label`)}
              placeholder={t(`steps.billingContact.fields.email.placeholder`)}
              autocomplete="email"
              name="email"
              shrink
              data-testid="email"
              disabled={useCustomerContact}
            />

            <PhoneInput
              required
              margin="normal"
              fullWidth
              label={t('steps.billingContact.fields.phone.placeholder')}
              autocomplete="phone"
              name="phone"
              autoFocus
              shrink
              data-testid="phone"
              disabled={useCustomerContact}
            />
          </Box>
        </Box>

        <Box>
          <Button
            type="submit"
            size="lg"
            fullWidth
            data-testid="billing-info-submit-button"
            disabled={!formState.isValid}
            color="gradient"
          >
            {t('steps.billingContact.buttons.saveAndContinue')}
          </Button>
        </Box>
      </Form>
    </FormProvider>
  );
};
