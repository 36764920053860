import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from 'ui';
import { DeleteOutline } from 'ui/icons';

import { isCompletedAddress, isCompletedMeteringPoint, MeteringPoint } from '~/services/firestore';
import { OptionCard } from '~/ui';

const Value = ({ children }: { children: React.ReactNode }) => (
  <Typography display="inline" variant="body2" fontWeight={400}>
    {children}
  </Typography>
);

interface LabelProps {
  children: React.ReactNode;
  hasError?: boolean;
}

const Label = ({ children, hasError }: LabelProps) => (
  <Typography display="inline" variant="body1" color={hasError ? 'error.main' : undefined} fontWeight={600}>
    {children}
  </Typography>
);

interface MeteringPointCardProps {
  meteringPoint: MeteringPoint;
  editMeteringPoint: () => void;
  removeMeteringPoint: () => void;
}

export const MeteringPointCard = ({
  meteringPoint,
  editMeteringPoint,
  removeMeteringPoint,
}: MeteringPointCardProps) => {
  const { t } = useTranslation('createMeteringPointWizard');
  const { name, meteringPointId, address } = meteringPoint;
  const isValid = isCompletedMeteringPoint(meteringPoint);
  const isAddressValid = isCompletedAddress(address);

  return (
    <OptionCard
      title={name || t('steps.confirmation.missingData')}
      titleColor={!name ? 'error.main' : undefined}
      onClick={editMeteringPoint}
      selected={!isValid}
      selectedColor="error.main"
      action={
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            removeMeteringPoint();
          }}
          data-testid={`remove-metering-point-${meteringPointId}`}
        >
          <DeleteOutline fontSize="medium" />
        </IconButton>
      }
      data-testid={`edit-metering-point-${meteringPointId}`}
    >
      <Box>
        <Label hasError={!meteringPointId}>{t('meteringPointId')}: </Label>
        <Value>{meteringPointId}</Value>
      </Box>

      <Box>
        <Label hasError={!isAddressValid}>{t('address')}: </Label>
        {address?.region && <Value>{address?.region},</Value>}
        <Value>
          {address?.postCode && `${address.postCode},`} {address?.settlement && `${address.settlement},`}
        </Value>
        {address?.area && <Value>{address?.area},</Value>}
        <Value>
          {address?.addressLine1} {address?.addressLine2}
        </Value>

        {address?.region ||
        address?.postCode ||
        address?.settlement ||
        address?.addressLine1 ||
        address?.addressLine2 ? null : (
          <Value>{t('steps.confirmation.missingData')}</Value>
        )}
      </Box>
    </OptionCard>
  );
};
