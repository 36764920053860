import { FeatureApiResponse, GrowthBook as ServerSideGrowthBook } from '@growthbook/growthbook';

import { FeatureFlagsProvider, FeatureFlagsProviderAppRouter, IfFeatureEnabled } from './components';
import { useFeatureIsOn } from './hooks';
import { useGrowthBook } from './hooks/useGrowthBook';
import { getGrowthBookPayload } from './utils/getGrowthBookPayload';
import growthbookMiddleware from './utils/growthbookMiddleware';
import { initServerGrowthBook } from './utils/serverSideGrowthBook';
import { setAttributes } from './utils/setAttributes';

export {
  FeatureFlagsProvider,
  FeatureFlagsProviderAppRouter,
  getGrowthBookPayload,
  growthbookMiddleware,
  IfFeatureEnabled,
  initServerGrowthBook,
  ServerSideGrowthBook,
  setAttributes,
  useFeatureIsOn,
  useGrowthBook,
};
export type { FeatureApiResponse };
