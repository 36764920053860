import { Configuration, GetAllProtocolsV1Request, ProtocolApi } from '@toki-power/public-api';

import { Page, Protocol } from '../types';

export type ProtocolsRequestOptions = GetAllProtocolsV1Request;

type ProtocolReturnType = {
  getAll: (options: ProtocolsRequestOptions) => Promise<Page<Protocol>>;
  get: (protocolId: string) => Promise<Protocol>;
};

export const protocolApi = (config: () => Promise<Configuration>): ProtocolReturnType => {
  const api = async () => new ProtocolApi(await config());

  const getAll = async (options: ProtocolsRequestOptions) =>
    (await api()).getAllProtocolsV1({
      page: options.page,
      size: options.size,
    });

  const get = async (protocolId: string) => (await (await api()).getProtocolV1({ protocolId })).data;

  return {
    getAll,
    get,
  };
};
