'use client';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { auth, onAuthStateChanged } from 'firebase-client';
import { useEffect, useState } from 'react';
import { Loader } from 'ui';

const gb = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
});
gb.init({
  // Optional, enable streaming updates
  streaming: true,
});

const FeatureFlagsProviderAppRouter = ({ children }: { children: React.ReactNode }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
    onAuthStateChanged(auth, (user) => {
      if (!user?.email) return;

      gb.setAttributes({ email: user.email });
      setIsReady(true);
    });
  }, []);

  return isReady ? <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider> : <Loader />;
};

export default FeatureFlagsProviderAppRouter;
