import { useTranslation } from 'react-i18next';

import { useCreateCustomer } from '~/customer';
import { DataSection } from '~/ui';

import { Value } from '../Value';

interface Props {
  onClick: (step: string) => void;
}

export const PersonalId = ({ onClick }: Props) => {
  const { t } = useTranslation('createCustomerWizard');
  const { getCustomerData } = useCreateCustomer();
  const { personalId } = getCustomerData();

  return (
    <DataSection
      label={t('steps.confirmation.personalId')}
      Value={<Value>{personalId || t('steps.confirmation.noData')}</Value>}
      onClick={() => onClick('personalId')}
      error={!personalId ? t('steps.confirmation.error') : ''}
      data-testid="personal-id"
    />
  );
};
