export const i18nConfig = {
  defaultNS: 'common',
  fallbackLng: 'bg',
  interpolation: {
    escapeValue: false,
  },
  lng: 'bg',
  ns: [
    'common',
    'home',
    'validation',
    'auth',
    'userImpersonation',
    'customers',
    'meteringPoints',
    'invoices',
    'brand',
    'register',
    'createContractWizard',
    'createCustomerWizard',
    'createMeteringPointWizard',
    'protocols',
  ],
};
