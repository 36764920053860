import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box } from 'ui';

import { usePaymentNotification } from '~/payment';
import { PaymentStatus } from '~/services/firestore/payment/types';

type StatusNotificationMap = {
  [key in PaymentStatus]?: {
    messageKey: string;
    severity: 'success' | 'info';
    color: string;
  };
};

const STATUS_NOTIFICATION_MAP: StatusNotificationMap = {
  [PaymentStatus.PROCESSING]: {
    color: 'notifications.orange',
    messageKey: 'paymentProcessingNotification',
    severity: 'info',
  },
  [PaymentStatus.PAID]: {
    color: 'notifications.green',
    messageKey: 'paymentSuccessNotification',
    severity: 'success',
  },
};

interface Props {
  invoiceStatus: string;
}

const PaymentNotification = ({ invoiceStatus }: Props) => {
  const lastInvoiceStatus = useRef(invoiceStatus);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const { t } = useTranslation('invoices');
  const { getNotificationStatus } = usePaymentNotification();
  const notificationStatus = getNotificationStatus();

  const message =
    notificationStatus && STATUS_NOTIFICATION_MAP[notificationStatus]
      ? STATUS_NOTIFICATION_MAP[notificationStatus]
      : null;

  useEffect(() => {
    // Show success message when invoice status changes from Unpaid to Paid
    if (message && invoiceStatus === 'Paid' && lastInvoiceStatus.current === 'Unpaid') {
      setIsMessageVisible(true);
      lastInvoiceStatus.current = invoiceStatus;

      setTimeout(() => {
        setIsMessageVisible(false);
      }, 10000);
    }

    // Show processing message when invoice status changes to Processing
    if (invoiceStatus === 'Unpaid' && message) {
      setIsMessageVisible(true);
    }
  }, [invoiceStatus, message]);

  if (!message || !isMessageVisible) {
    return null;
  }

  return (
    <Box
      display="flex"
      flex={1}
      sx={{
        backgroundColor: 'primary',
        display: 'flex',
        justifyContent: 'center',
      }}
      mb={2}
    >
      <Alert severity={message.severity} variant="filled" sx={{ bgcolor: message.color }}>
        {t(message.messageKey)}
      </Alert>
    </Box>
  );
};

export { PaymentNotification };
