import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { useTranslation } from 'react-i18next';
import { Box } from 'ui';

import { useCreateCustomer } from '~/customer';
import { OptionCard, StepTitle } from '~/ui';

export const CustomerTypeStep = () => {
  const { t } = useTranslation();
  const { setCustomerType, customerData, navigateWithRedirect, businessStepNavigator, residentStepNavigator } =
    useCreateCustomer();
  const { customerType } = customerData;

  const onSelection = (type: 'person' | 'company') => {
    setCustomerType(type);

    navigateWithRedirect(() => {
      if (type === 'person') {
        residentStepNavigator?.setCurrentStep('fillInOptions');
        return;
      }

      businessStepNavigator?.setCurrentStep('fillInOptions');
    });
  };

  const handleResidentialClick = () => {
    trackTagManagerEvent(OnboardingEvents.residentialCustomerType);
    onSelection('person');
  };

  const handleBusinessClick = () => {
    trackTagManagerEvent(OnboardingEvents.businessCustomerType);
    onSelection('company');
  };

  return (
    <Box>
      <Box mb={2}>
        <StepTitle>{t('createCustomerWizard:customerTypeOptions.title')}</StepTitle>
      </Box>

      <Box mb={2}>
        <OptionCard
          title={t('createCustomerWizard:customerTypeOptions.residential.title')}
          description={t('createCustomerWizard:customerTypeOptions.residential.description')}
          onClick={handleResidentialClick}
          selected={customerType === 'person'}
          data-testid="residential-option"
        />
      </Box>

      <OptionCard
        title={t('createCustomerWizard:customerTypeOptions.business.title')}
        description={t('createCustomerWizard:customerTypeOptions.business.description')}
        onClick={handleBusinessClick}
        selected={customerType === 'company'}
        data-testid="business-option"
      />
    </Box>
  );
};
