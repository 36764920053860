export enum Errors {
  AUTH_ANONYMOUS_SIGN_IN_FAILURE = 'AUTH_ANONYMOUS_SIGN_IN_FAILURE',
  UNHANDLED_ERROR = 'UNHANDLED_ERROR',
  UNHANDLED_NAVIGATION_LAYOUT_ERROR = 'UNHANDLED_NAVIGATION_LAYOUT_ERROR',
  DOWNLOAD_GENERATED_CONTRACT_FAILURE = 'DOWNLOAD_GENERATED_CONTRACT_FAILURE',
  HANDLE_COMPLETED_REQUEST_FAILURE = 'HANDLE_COMPLETED_REQUEST_FAILURE',
  UPLOAD_SIGNED_CONTRACT_FAILURE = 'UPLOAD_SIGNED_CONTRACT_FAILURE',
  UPLOAD_INVOICE_FAILURE = 'UPLOAD_INVOICE_FAILURE',
  VALIDATE_USER_RESPONSE_FAILURE = 'VALIDATE_USER_RESPONSE_FAILURE',
  TRADE_REGISTRY_API_ERROR = 'TRADE_REGISTRY_API_ERROR',
  AUTH_PASSWORD_LOGIN_FAILURE = 'AUTH_PASSWORD_LOGIN_FAILURE',

  AUTH_SIGN_UP_LOGIN_FAILURE = 'AUTH_SIGN_UP_LOGIN_FAILURE',
  AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE',
  UNABLE_TO_BUILD_EPAY_PAYLOAD = 'UNABLE_TO_BUILD_EPAY_PAYLOAD',
}
