/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { Autocomplete, Checkbox, FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import { regions } from 'forms/components/RegionAutocomplete/regions';
import { useTranslation } from 'react-i18next';
import { Box, Form } from 'ui';
import { bool, object, string } from 'yup';

import { areaCities, areas, settlements } from '~/config';
import { useCreateMeteringPoint } from '~/meteringPoint';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  area?: string;
  postCode: string;
  region: string;
  settlement: string;
  street: string;
  useAccountAddress?: boolean;
}

export const MeteringPointAddressStep = () => {
  const { t } = useTranslation('createMeteringPointWizard');
  const {
    goToNextStep,
    getMeteringPoint,
    setAddress,
    currentMeteringPointIndex,
    navigateWithRedirect,
    getAccountAddress,
  } = useCreateMeteringPoint();

  const { address } = getMeteringPoint(currentMeteringPointIndex);

  const requiredMessage = t('steps.address.fields.required');

  const resolver = yupResolver(
    object({
      area: string().when('settlement', {
        is: (value: string) => areaCities.includes(value),
        then: () => string().required(requiredMessage).nullable(),
      }),
      postCode: string().required(requiredMessage),
      region: string().required(requiredMessage),
      settlement: string().required(requiredMessage),
      street: string().required(requiredMessage),
      useAccountAddress: bool(),
    })
  );

  const { handleSubmit, watch, resetField, setValue, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      area: address?.area || '',
      postCode: address?.postCode || '',
      region: address?.region || '',
      settlement: address?.settlement || '',
      street: address?.addressLine1 || '',
      useAccountAddress: false,
    },
    mode: 'onChange',
    resolver,
  });

  const useAccountAddress = watch('useAccountAddress');

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.meteringPointAddress);

    setAddress(
      {
        addressLine1: values.street,
        addressLine2: '',
        area: values.area || '',
        municipality: '',
        postCode: values.postCode,
        region: values.region,
        settlement: values.settlement,
      },
      currentMeteringPointIndex
    );

    navigateWithRedirect(goToNextStep);
  };

  const region = watch('region');
  const settlement = watch('settlement');

  const handleUseAccountAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const accountAddress = getAccountAddress();

    const meteringPointAddress = {
      area: accountAddress.area || '',
      postCode: accountAddress.postCode || '',
      region: accountAddress.region || '',
      settlement: accountAddress.settlement || '',
      street: accountAddress.addressLine1,
    };

    const isChecked = event.target.checked;

    if (isChecked) {
      Object.entries(meteringPointAddress).forEach(([key, value]) =>
        setValue(key as keyof typeof meteringPointAddress, value)
      );
    } else {
      Object.keys(meteringPointAddress).forEach((key) => resetField(key as keyof typeof meteringPointAddress));
    }
  };

  return (
    <FormProvider {...{ formState, handleSubmit, resetField, setValue, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.address.title')}</StepTitle>
          </Box>

          <Checkbox
            name="useAccountAddress"
            label={t('steps.address.fields.useAccountAddress.label')}
            onChange={handleUseAccountAddressChange}
            data-testid="use-account-address"
          />

          <Box mb={2} mt={1}>
            <TextInput
              required
              autocomplete="post-code"
              data-testid="post-code"
              label={t('steps.address.fields.postCode.label')}
              name="postCode"
              shrink
              placeholder={t('steps.address.fields.postCode.placeholder')}
            />
          </Box>

          <Box mb={2}>
            <Autocomplete
              label={t(`steps.address.fields.region.label`)}
              data-testid="region"
              name="region"
              autocomplete="region"
              required
              options={regions}
              shrink
              placeholder={t('steps.address.fields.region.placeholder')}
            />
          </Box>

          <Box mb={2}>
            <Autocomplete
              label={t('steps.address.fields.settlement.label')}
              name="settlement"
              autocomplete="settlement"
              required
              disabled={!region}
              data-testid="settlement"
              options={region ? settlements[region] : []}
              shrink
              placeholder={t('steps.address.fields.settlement.placeholder')}
            />
          </Box>

          {areaCities.includes(settlement) && (
            <Box mb={2}>
              <Autocomplete
                label={t(`steps.address.fields.area.label`)}
                name="area"
                autocomplete="area"
                required
                disabled={!settlement}
                data-testid="area"
                options={settlement ? areas[settlement.split(' ')[1]] : []}
                shrink
                placeholder={t('steps.address.fields.area.placeholder')}
              />
            </Box>
          )}

          <Box mb={2}>
            <TextInput
              required
              data-testid="street"
              autocomplete="address"
              label={t('steps.address.fields.street.label')}
              name="street"
              shrink
              placeholder={t('steps.address.fields.street.placeholder')}
            />
          </Box>
        </Box>

        <ContinueButton
          data-testid="metering-point-address-submit-button"
          disabled={!useAccountAddress && !formState.isValid}
        />
      </Form>
    </FormProvider>
  );
};
