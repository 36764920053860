import logger from 'logger';
import { addError, Errors } from 'rum';

import { useAuthFetch } from './useAuthFetch';

interface PayloadParams {
  invoiceNumber: string;
  amount: number;
}

type EPayPayloadResponseType<T> = {
  data: T;
  error: string | null;
};

type UseEPayPayloadReturnType<T> = {
  getPayload: (payload: PayloadParams) => Promise<EPayPayloadResponseType<T>>;
};

const useEPayPayloadRequest = <T>(): UseEPayPayloadReturnType<T> => {
  const { request } = useAuthFetch();

  const getPayload = async ({ amount, invoiceNumber }: PayloadParams) => {
    const response = await request('/api/payments/epay-payload', {
      body: JSON.stringify({
        amount,
        invoiceNumber,
      }),
      method: 'POST',
    });

    const { error, data } = (await response.json()) as EPayPayloadResponseType<T>;

    if (!response.ok) {
      logger.error('Unable to build ePay payload', { error });
      addError(Errors.UNABLE_TO_BUILD_EPAY_PAYLOAD, 'Unable to build ePay payload');
    }

    return { data, error };
  };

  return { getPayload };
};

export { useEPayPayloadRequest };
