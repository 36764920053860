'use client';

import Image from 'next/image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from 'ui';

interface Props {
  src: string;
}

export const MeteringPointParticle = ({ src }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation('meteringPoints');
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Typography>
        {`${t('pointInfo.invoiceInfo')}. `}
        <Box component="span" color="secondary.main" sx={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>
          {t('pointInfo.example')}
        </Box>
      </Typography>

      <Dialog onClose={handleClose} open={showModal} maxWidth="md">
        <DialogContent>
          <Image src={`/images/measuringPointLabels/${src}.jpg`} alt="invoice" width={700} height={500} />
          <Typography>{t('pointInfo.exampleInfo')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('common:close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
